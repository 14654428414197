@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;1,200;1,300;1,400;1,500&display=swap');

html {
  overflow-x: clip;
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Karla', sans-serif;
  color: darkslategrey;

  scrollbar-gutter: stable both-edges;

}

.content {

  padding: 0 10vw;
  max-width: 100%;

}

/*

  HEADER

*/

header {

  margin: 0 auto;
  padding: 0 100px;

  /* background-color: #f2f2f2; */
  opacity: 98%;
  color: darkslategrey;


  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.navbar {
  display: flex;
  align-items: center;

  height: 8vh;
  
  max-width: 100%;
  /* border-bottom: 1px solid #f2f2f2ec; */

  font-size: 20px;

  
  /* background-color: aliceblue; */
}

.navbar h1{
  font-size: 30px;
  margin: 0 auto;

}
.navbar a {
  text-decoration: none;
  padding: 0 20px;
  color: darkslategrey;
}

.navbar .links {
  margin-left: auto;
}

/*

  DYNAMIC HEADER

*/
@media screen and (max-width: 600px) {
  header {
    padding: 0 10px;
    /* height: 20vh; */
  }
  .navbar {
    padding-right: 10px;    
  }
  .navbar a {
    margin-right: auto;
  }

  .links {
    font-size: 25px;
    padding-bottom: 10px;
    margin: 0 -10px;

    display: flex;
    flex-direction: column;

    position: absolute;
    width: 100vw;
  }
  .links a {
    color: lightslategray;
    text-decoration: none;
    padding: 5px 30px;
  }

  #hamburger {
    padding: 10px
  }

}


@media screen and (max-width: 1000px) {

  header {
    padding: 0 10px;
  }
}

@media screen and (max-width: 600px) {
  /*  hamburger menu */
  
}



#homepage {
  /* background-color: #FFF; */

  margin: 0 auto;
  padding-top: 8vh;

  min-width: 100%;
  min-height: 74vh;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  /* border-bottom: 1px solid lightslategray; */
}

#homepage h1 {
  /* margin: 50px auto; */
  padding: 0 auto;
  margin: auto;
  font-size: 80px;

}

#title-div {
  height: 74vh;
  width: 100vw;
  display: flex;
  align-items: center;

  border-bottom: 1px solid lightgray;
}

#front-img {
  margin-top: 8vh;
  max-width: 100vw;
  width: auto;
  /* height: 92vh; */
  max-height: 92vh;
}

.section {
  height: auto;
  min-width: 100%;
  max-width: 100%;
  min-height: 90vh; 

  text-align: left;

  /* margin: 0 5%; */
  padding-top: 40px;

  /* border-bottom: 1px solid #000; */
  
}


/*

ABOUT

*/
.section h2 {
  font-size: 40px;
  padding: 0 5%;
}
  
.about-content {
  display: flex;
  justify-content: space-between;
  align-self: baseline;

  padding: 0 5%;
}

.about-description {
  max-width: 50%;

  justify-self: baseline;
}

.about-content p {
  font-size: larger;
}

.profile-picture {
  width: 40%;
  height: auto;
  border-radius: 50%;

  align-self: center;
  justify-self: right;

  margin: auto;

}

/*

  DYNAMIC ABOUT

*/

@media screen and (max-width: 1000px) {
  .about-content {
    flex-direction: column-reverse;
  }

  .about-description {
    max-width: 100%;
    text-align: justify;
  }

  .profile-picture {
    justify-self: center;
    width: 60%;
  }
}

@media screen and (max-width: 600px) {
  .section h2 {
    padding: 0;
    text-align: center;
    font-size: 30px;
  }

  .profile-picture {
    justify-self: center;
    width: 90%;
  }

  .about-content p {
    font-size: large;
  }
}

/*

  EXPERIENCE

*/

.project-gallery {
  display: flex;
  justify-content: center;
  align-self: baseline;

  flex-wrap: wrap;

  margin: 5%;

}
.project-tile {

  width: 250px;
  height: auto;

  margin: 30px;

  display: flex;
  text-align: center;
  
  flex-basis: 20%;

}

.project-tile a {
  text-decoration: none;
  color: lightslategray;
  font-weight: 500;
}

.project-tile img {
  width: 100%;
  height: auto;
 
  border-radius: 5%;

  transition: transform .25s, filter 0.5s ease-in-out;
  /* filter: grayscale(60%); */
}

.project-tile:hover img{
  filter: grayscale(0);
  transform: scale(1.1);
}

.project-tile p {
  font-size: 20px;
}

/*

  DYNAMIC EXPERIENCE

*/

@media screen and (max-width: 1300px) {

  .project-tile {
    flex-basis: 40%;
  }

}

@media screen and (max-width: 900px) {

  .project-tile {
    margin: 20px;
    flex-basis: 300px;
  }

}

/*

  PROJECT

*/

.project-intro-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  /* filter: blur(2px); */
  z-index: -1;

  position: absolute;

  min-width: 100vw;
  min-height: 70vh;

  padding-top: 8vh;
  margin: 0 -10vw;
}

.project-intro {
  margin: auto;
  text-align: center;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  min-height: 74vh;
  /* margin: 0 -10vw; */
  
  /* padding-top: 8vh; */

  border-bottom: 1px solid lightgray;


  /* color: #FFF; */

  /* background-color: rgba(255, 255, 255, .15);   */
  /* backdrop-filter: blur(5px); */
}

.project-intro h1{
  font-size: 60px;
}

.project-intro img {
  position: relative;
  z-index: -1;
  max-height: inherit;
  max-width: auto;

}

.project-content {
  height: auto;
  max-width: 65%;
  min-height: 90vh; 

  text-align: left;
  font-size: 16px;

  margin: auto;
  /* padding: 10vh 0; */
}

.project-content h2 {
  font-size: 40px;
}

.project-content img {
  max-height: auto;
  max-width: 100%;
}
.markdown {
  line-height: 35px;  
  margin: 10%;
}

.markdown h1 {
  font-size: 30px;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;

}

.markdown h2 {
  font-size: 20px;
}

.markdown pre {
  background-color: #eeeeee;
  padding: 15px;
  line-height: 20px;
  text-wrap: wrap;
  font-size: small;
  max-width: 90%;
  border-radius: 10px;
}

/* This is needed to remove extra padding created at the start of large code blocks "pre" because the padding from "code" is also applied to them. */
.markdown pre code {
  padding: 0;
}
.markdown code {
  background-color: #eeeeee;
  padding: 2px 8px;
  border-radius: 5px;
  color: #a02a01;
  text-align: center;
}

/*

  DYNAMIC PROJECT

*/
@media screen and (max-width: 800px) {
  .project-content {
    max-width: 85%;
  }
}

@media screen and (max-width: 500px) {
  .project-intro h1 {
    font-size: 50px;
  }

  .project-intro h3 {
    margin: 0 5%;
  }

  .project-content h2 {
    font-size: 35px;
  }

  .markdown {
    margin: 10% 0;
    font-size: small;
    line-height: 25px;
  }

  .markdown h1 {
    font-size: 25px;
  }

  .markdown h2 {
    font-size: 20px;
  }

  .markdown ul, ol {
    margin-left: -15px;
  }
}




/*

  RESUME

*/

#resume-button {
  min-width: 50px;
  min-height: 50px;

  max-width: 50px;
  max-height: 50px;

  margin: auto 30px 30px auto;
  padding: 0 20px 20px 0;

  position: -webkit-sticky;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 3;
  
  /* border-radius: 50%;
  border: 1px solid lightslategray; */
  
  transition: transform .25s, filter 0.5s ease-in-out;
}

#resume-button img {
  width: 100%;
  height: auto;
  
  filter: invert(34%) sepia(4%) saturate(3351%) hue-rotate(148deg) brightness(91%) contrast(92%);
}

@keyframes highlight {
  0%{
      border-radius: 50%;
      
  }
  100% {
      border-radius: 20%;
    border: 1px solid lightslategray;
  }
}

#resume-button:hover {
  /* transform: scale(1.1); */
  border-radius: 50%;
  border: 1px solid lightslategray;
  padding: 19px;
  background-color: #FFF;
}

#pdf-container {
  pointer-events: all; /* Click underneath other elements */

  display: flex;
  position: fixed; 
  margin: auto;
  z-index: 2;

  min-height: 100vh;
  min-width: 100vw;
  margin: auto;

  
  /* filter: blur(5px); */

}

#pdf-viewer::-webkit-scrollbar {
  display: none;
}

#pdf-viewer {
  pointer-events: all;

  opacity: 98%;
  border-radius: 10px;
  border: 2px solid lightslategray;
  /* box-shadow: 0px 5px 20px 1px; */

  box-shadow: 0px 5px 20px 2px rgba(0,0,0,0.32);

  overflow-x: hidden;
  overflow-y: scroll;

  max-height: 80vh; 
  max-width: 80vw;

  /* min-height: 80vh;  */
  min-width: 80vw;

  margin: auto;
}

/*

  DYNAMIC RESUME

*/
@media screen and (max-width: 600px) {
  #resume-button {
    min-width: 40px;
    min-height: 40px;

    max-width: 40px;
    max-height: 40px;

    border-radius: 50%;
    border: 1px solid lightslategray;
    padding: 19px;
    background-color: #FFF;
  }
}




/*

  FOOTER

*/

#footer {
  min-width: 100vw;
  min-height: 40vh;

  margin-top: 100px;

  position: relative;
  color: darkslategray;
  

  background-color: #f2f2f2;
}

.footer-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 30vh;
}

.footer-content h2 {
  font-size: 40px;
}

.contact-links {
  display: flex;
  flex-direction: row;


  align-content: center;
  justify-content: center;
  
}

.contact-item{
  max-width: 50px;
  height: auto;
  text-align: center;
  margin: 0 20px;

  align-content: center;

  transition: transform .25s, filter 0.5s ease-in-out;
  filter: invert(34%) sepia(4%) saturate(3351%) hue-rotate(148deg) brightness(91%) contrast(92%);
}

.contact-item:hover {
  transform: scale(1.1);
}

.copyright {
  position: absolute;
  bottom: 0;
  text-align: center;

  min-width: 100vw;
  border-top:1px solid lightgray;
}

/*

  DYNAMIC FOOTER

*/
@media screen and (max-width: 500px) {
  .footer-content {
    margin: auto;
  }
  .footer-content h2 {
    padding: 0 10px;
    font-size: 30px;
  }
  .contact-item {
    max-width: 40px;
  }
}

